const STANDARD_DESCRIPTION = 'Digital loyalty made easy for everyone. Loyalty stamp cards for Apple Wallet and Google Pay, simple to make, easy to use and track.';

const offers = {
  '@type': 'AggregateOffer',
  offeredBy: {
    '@type': 'Organization',
    email: 'darren@total-loyalty.com',
    name: 'Total Loyalty',
  },
  highPrice: '9.99',
  lowPrice: '1.49',
  offerCount: '4',
  priceCurrency: 'GBP',
  priceSpecification: [
    {
      '@type': 'UnitPriceSpecification',
      name: 'Start up',
      price: '1.49',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    }, {
      '@type': 'UnitPriceSpecification',
      name: 'Bronze',
      price: '2.49',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    }, {
      '@type': 'UnitPriceSpecification',
      name: 'Silver',
      price: '4.99',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    }, {
      '@type': 'UnitPriceSpecification',
      name: 'Gold',
      price: '9.99',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    },
  ],
};

export const isRelatedTo = [
  {
    '@type': 'Product',
    name: 'Apple Wallet',
    url: 'https://apps.apple.com/us/app/apple-wallet/id1160481993',
  },
  {
    '@type': 'Product',
    name: 'Apple Pay',
    url: 'https://www.apple.com/uk/apple-pay/',
  },
  {
    '@type': 'Product',
    name: 'Google Pay',
    url: 'https://pay.google.com/',
  },
];

export const organisation = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Total Loyalty',
  logo: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
  sameAs: [
    'https://twitter.com/LoyaltyTotal',
    'https://www.instagram.com/total.loyalty.card',
  ],
  contactPoint: [
    {
      '@type': 'ContactPoint',
      email: 'darren@total-loyalty.com',
      contactType: 'customer support',
    },
  ],
};

export const services = {
  '@context': 'https://schema.org/',
  '@type': 'Service',
  serviceType: 'Digital Loyalty Card',
  provider: {
    '@type': 'Website',
    name: 'Total Loyalty',
  },
  hasOfferCatalog: {
    '@type': 'OfferCatalog',
    name: 'Digital Loyalty',
    itemListElement: [
      {
        '@type': 'OfferCatalog',
        name: 'Digital Loyalty Stamp Card',
        itemListElement: [
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Start 100 active cards',
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Bronze 500 active cards',
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Silver 2000 active cards',
            },
          }, {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Gold 10000 active cards',
            },
          },
        ],
      },
    ],
  },
};

export const product = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  '@id': '',
  name: 'Total Loyalty',
  brand: {
    '@type': 'Brand',
    name: 'Total Loyalty',
  },
  sku: 'Total_Loyalty',
  description: STANDARD_DESCRIPTION,
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
  offers,
};

export const software = {
  '@context': 'https://schema.org',
  '@type': 'SoftwareApplication',
  name: 'Total Loyalty',
  applicationCategory: 'BusinessApplication',
  operatingSystem: 'all',
  url: 'https://www.total-loyalty.com',
  offers,
};

export const video = {
  '@context': 'http://schema.org',
  '@type': 'VideoObject',
  name: 'Total Loyalty, digital loyalty made easy for everyone.',
  description: 'Digital loyalty made easy for everyone. Loyalty stamp cards for Apple Wallet and Google Pay, simple to make, easy to use and track.  https://www.total-loyalty.com',
  thumbnailUrl: 'https://i.ytimg.com/vi/8gtYahVCOpw/default.jpg',
  uploadDate: '2020-10-13T09:02:23Z',
  duration: 'PT1M15S',
  embedUrl: 'https://www.youtube.com/embed/8gtYahVCOpw',
  interactionCount: '2',
};

export const breadcrumb = ({ url, name, position }) => ({
  '@type': 'ListItem',
  item: {
    '@id': url,
    name,
  },
  position,
});

export const breadcrumbs = (otherCrumbs) => {
  const crumbs = [
    {
      '@type': 'ListItem',
      item: {
        '@id': 'https://www.total-loyalty.com',
        name: 'Home',
      },
      position: 1,
    },
  ];
  const rtn = crumbs.push(...otherCrumbs);
  return rtn;
};
