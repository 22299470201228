const offers = {
  '@type': 'AggregateOffer',
  offeredBy: {
    '@type': 'Organization',
    email: 'darren@total-loyalty.com',
    name: 'Total Loyalty',
  },
  highPrice: '9.99',
  lowPrice: '1.49',
  offerCount: '4',
  priceCurrency: 'GBP',
  priceSpecification: [
    {
      '@type': 'UnitPriceSpecification',
      name: 'Start up',
      price: '1.49',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    }, {
      '@type': 'UnitPriceSpecification',
      name: 'Bronze',
      price: '2.49',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    }, {
      '@type': 'UnitPriceSpecification',
      name: 'Silver',
      price: '4.99',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    }, {
      '@type': 'UnitPriceSpecification',
      name: 'Gold',
      price: '9.99',
      priceCurrency: 'GBP',
      referenceQuantity: {
        '@type': 'QuantitativeValue',
        value: '1',
        unitCode: 'MON',
      },
    },
  ],
};

export const webpage = ({
  email,
  url,
  description,
  title = 'Total Loyalty',
  image = 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
  dateModified,
}) => ({
  '@context': 'http://schema.org',
  '@type': 'WebPage',
  name: title,
  url,
  description,
  headline: 'Digital loyalty made easy for everyone',
  inLanguage: 'en-GB',
  copyrightYear: '2019',
  datePublished: '2019-11-01T00:00:00',
  dateModified,
  offers,
  image: {
    '@type': 'ImageObject',
    url: image,
  },
});
