const KEYWORDS = 'Apple Wallet, Google Pay, Stamp Card, Loyalty Card, Loyalty Scheme, Mobile loyalty, Freelancer, Gig Economy, Digital loyalty, Coffee Loyalty, Covid Safe, Covid Aware, Covid Loyalty';

export const freeTierArticle = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty simple and affordable',
  alternativeHeadline: 'Try Total Loyalty with all features enabled for free',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#build-loyalty',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'Build a Loyalty Card with the full functionality of Total Loyalty',
  articleBody: 'Build a Loyalty Card and share it with an Android device for Google Pay, and a iOS device for Apple Wallet.  We are always adding new functionality and every subscription including our free tier has full access to all features.',
};

export const landingArticleOne = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty simple and affordable',
  alternativeHeadline: 'Loyal customers are returning customers',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#build-loyalty',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'Digital Loyalty service to empower small businesses',
  articleBody: 'Total Loyalty is a software service that empowers small businesses with the ability to create Digital Loyalty Cards to build stronger relationships with their customers. Backed by analytics, digital loyalty cards allow you to thank your customers for their trust and promote long-term loyalty. Encourage your customers to come back for more, all through their most trusted device — their smartphone.',
};

export const landingArticleTwo = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty that\'s Always Available',
  alternativeHeadline: 'Loyalty on your customers smart phones, your brand in their pocket',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#always-available',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'A loyalty program that\'s always with your customers',
  articleBody: 'We carry around our smartphones all the time and use them for nearly everything, from listening to music to boarding passes to ordering rides and so much more. Integrating with your customers mobile wallet enables your business to fit right into their routine. Providing a Total Loyalty digital card ensures your customers that they can always access their rewards, and this convenience could attract more of them to adopt your loyalty program.',
};

export const landingArticleThree = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty sustainably',
  alternativeHeadline: 'A Greener Solution for loyalty, lessen your carbon footprint',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#greener-solution',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'Lessen your carbon footprint and reduce pollutive waste',
  articleBody: 'Digital loyalty cards don’t just save you money on the printing of physical stamp cards, you also lessen your carbon footprint by reducing waste. Total Loyalty allows you to update and share loyalty cards without the hassle of having to reprint and redistribute. Your bottom-line will appreciate the expense save, your customers will appreciate less cards that add clutter to their wallets, and the planet will appreciate the paperless solution.',
};

export const landingArticleFour = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty Covid Aware',
  alternativeHeadline: 'Covid safe loyalty, features to help with social distancing',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#covid-aware',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'Features to help with social distancing',
  articleBody: 'The way you interact with your customers may have changed and we wanted to make sure Total Loyalty would help. We held back the release of our software to add features that improved Total Loyalty\'s use for social distancing. Total Loyalty allows you to share digital loyalty cards simply and safely, through printable QR codes and sharing via messaging and email. Total Loyalty allows you to managing a customer loyalty without your customer being present, simply search for your customers digital loyalty card and stamp or redeem.',
};

export const landingArticleFive = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty with inclusive features',
  alternativeHeadline: 'Fully featured at all subscriptions',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#inclusive-features',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'Native and new features',
  articleBody: 'Total Loyalty was built with your business in mind. We use Apple Wallet and Google Pay Loyalty Cards for the extra native features available like screen alerts and updatable content. Our team of developers are building new functionality and new features. New features will always be available at all subscription levels will at no extra cost.',
};

export const landingArticleSix = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty affordable & fair',
  alternativeHeadline: 'Affordable solution',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#affordable-fair',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'A price that suits your budget',
  articleBody: 'We want your business to access all the great features Total Loyalty offers at a price that suits your budget. Total Loyalty only charges for the cards your customers are actively using. Pay for 100 active cards and have 500 out in the world. The Loyalty cards that aren\'t helping your business, the cards not being used by your Loyal customers, you won\'t be charged for.',
};

export const landingArticleSeven = {
  '@context': 'https://schema.org',
  '@type': 'Article',
  headline: 'Total Loyalty, Digital Loyalty with no commitment',
  alternativeHeadline: 'Simple subscriptions, cancel or change anytime',
  image: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/landing_mobile.png',
  author: 'Total Loylaty',
  genre: 'Digital Loyalty',
  keywords: KEYWORDS,
  publisher: {
    '@type': 'Organization',
    name: 'Total Loyalty',
    logo: {
      '@type': 'ImageObject',
      url: 'https://total-loyalty-fixed.s3-eu-west-1.amazonaws.com/tl_words.png',
    },
  },
  url: 'https://www.total-loyalty.com/#affordable-fair',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': 'https://www.total-loyalty.com',
  },
  datePublished: '2020-10-24',
  dateCreated: '2020-10-24',
  dateModified: '2020-10-24',
  description: 'Simple subscriptions, cancel or change anytime',
  articleBody: 'Total Loyalty won\'t hold you to a contract.  There are no cancellation fees and no long term commitment. If our product isn\'t right for your business you can cancel at any time. We can\'t refund any time you\'ve paid for, our service is billed monthly. You can change your subscription level at anytime as your business changes.  If we don\'t offer a package that suits your needs please get in touch.',
};
