import React from 'react';
import loadable from '@loadable/component';
import { useStaticQuery, graphql } from 'gatsby';

import { webpage } from 'structured-data/web-page';

import {
  software,
  product,
  services,
  video,
} from 'structured-data';
import {
  freeTierArticle,
  landingArticleOne,
  landingArticleTwo,
  landingArticleThree,
  landingArticleFour,
  landingArticleFive,
  landingArticleSix,
  landingArticleSeven,
} from 'structured-data/landing-articles';

const Helmet = loadable(() => import('react-helmet'));

const Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteDescription
            contactEmail
            keywords
            basic {
              title
              description
              image
              url
            }
          }
        }
      }
    `,
  );

  const { siteMetadata = {} } = site || {};
  const {
    basic = {}, twitter = {},
    siteDescription = '',
    contactEmail = '', keywords = '',
  } = siteMetadata;

  const { url } = basic;

  const webpageStrucuredData = webpage({
    email: contactEmail,
    url,
    description: siteDescription,
    title: `Total Loyalty | ${basic.title}`,
    dateModified: '2020-09-30T00:00:00',
  });

  return (
    <Helmet
      title={basic.title}
      titleTemplate={`%s | ${basic.title}`}
      meta={[
        { name: 'color-scheme', content: 'dark light' },
        { name: 'description', content: `Total Loyalty | ${siteDescription}` },
        { name: 'keywords', content: keywords },

        // Google +
        { itemprop: 'name', content: basic.title },
        { itemprop: 'description', content: siteDescription },
        { itemprop: 'image', content: basic.image },

        // facebook
        { property: 'og:site_name', content: basic.title },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: basic.title },
        { property: 'og:description', content: siteDescription },
        { property: 'og:image:alt', content: siteDescription },
        { property: 'og:locale', content: 'en-GB' },
        { property: 'fb:app_id', content: '378480403182368' },

        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: twitter.contact },
        { name: 'twitter:title', content: twitter.title },
        { name: 'twitter:description', content: siteDescription },
        { name: 'twitter:creator', content: twitter.contact },
        { name: 'twitter:image', content: twitter.image },
        { name: 'twitter:image:alt', content: twitter.image },
        { name: 'twitter:image:src', content: siteDescription },
      ]}
    >
    <link property="og:image" href={basic.image}/>
    <link rel="canonical" href={url} />
    <script type="application/ld+json">{JSON.stringify(webpageStrucuredData)}</script>
    <script type="application/ld+json">{JSON.stringify(product)}</script>
    <script type="application/ld+json">{JSON.stringify(software)}</script>
    <script type="application/ld+json">{JSON.stringify(services)}</script>
    <script type="application/ld+json">{JSON.stringify(video)}</script>
    <script type="application/ld+json">{JSON.stringify(freeTierArticle)}</script>
    <script type="application/ld+json">{JSON.stringify(landingArticleOne)}</script>
    <script type="application/ld+json">{JSON.stringify(landingArticleTwo)}</script>
    <script type="application/ld+json">{JSON.stringify(landingArticleThree)}</script>
    <script type="application/ld+json">{JSON.stringify(landingArticleFour)}</script>
    <script type="application/ld+json">{JSON.stringify(landingArticleFive)}</script>
    <script type="application/ld+json">{JSON.stringify(landingArticleSix)}</script>
    <script type="application/ld+json">{JSON.stringify(landingArticleSeven)}</script>
    </Helmet>
  );
};

export default Header;
